import React from "react";
import { MoneyRecharge } from "../MoneyRecharge/MoneyRecharge";
import "./Calculadora_Recargas.css";

const Calculadora_Recargas = ({ country_name = "venezuela" }) => {
  const searchLangParams =
    typeof document !== "undefined"
      ? new URLSearchParams(window?.location?.search).get("lang") || "es"
      : "es";
  const searchCountryParams =
    typeof document !== "undefined"
      ? new URLSearchParams(window?.location?.search).get("country") ||
        country_name
      : country_name;

  return (
    <div className="money-convertor_calculator_recharges-page">
      <MoneyRecharge
        lang={searchLangParams}
        link
        country_name={searchCountryParams}
      />
    </div>
  );
};

export default Calculadora_Recargas;

//http://localhost:8000/calculadora-recargas?lang=en
//http://localhost:8000/calculadora-recargas
{
  /* <div
  style={{
    maxWidth: "900px",
    minWidth: "100%",
    height: "100vh",
    backgroundColor: "red",
  }}
>
  <iframe
    name="sendity"
    loading="lazy"
    title="sendity calculator"
    allow="fullscreen"
    scrolling="no"
    style={{
      width: "100%",
      height: "100%",
      border: "none",
    }}
    src="http://localhost:3000/calculadora"
  ></iframe>
</div>; */
}
